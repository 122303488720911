import React, { useCallback, useEffect } from "react";
import { useState, useRef } from "react";
import { VscCircleFilled } from "react-icons/vsc";

const ImageSlider = ({ slides, parentWidth }) => {
  const timerref = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  const goToNext = useCallback(() => {
    currentIndex === slides.length - 1
      ? setCurrentIndex(0)
      : setCurrentIndex(currentIndex + 1);
  }, [currentIndex, slides]);
  const goToPrevious = () => {
    currentIndex === 0
      ? setCurrentIndex(slides.length() - 1)
      : setCurrentIndex(currentIndex - 1);
  };
  useEffect(() => {
    console.log("useEffect");
    if (timerref.current) {
      clearTimeout(timerref.current);
    }
    timerref.current = setTimeout(() => {
      goToNext();
    }, 2000);

    return () => clearTimeout(timerref.current);
  }, [goToNext]);

  return (
    <div className="sliderstyles flex flex-col items-center justify-center md:flex-row gap-y-4 md:gap-x-8">
      {/* <div className="sliderstyles"> */}
      <style jsx>{`
        .sliderstyles {
          height: 100%;
        }
      `}</style>
      <div
        style={{
          backgroundImage: `url(${slides[currentIndex].url})`,
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          backgroudPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="flex flex-row md:flex-col">
        {slides.map((slide, slideIndex) => {
          return (
            <div className="text-black" key={slideIndex}>
              <VscCircleFilled
                className="h-10"
                onClick={() => goToSlide(slideIndex)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageSlider;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, removeFromCart, clearCart } from '../slices/cartslice';
import {
  AiOutlinePlusSquare,
  AiOutlineMinusSquare
} from "react-icons/ai";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { TRANSACTION_URL } from "../constants";
import { useGetMyDetailQuery } from "../slices/getQueries/userQueriesGet";
import { useGetPincodeDataQuery } from "../slices/getQueries/utilQueriesGet";
import { usePreTransactionMutation } from "../slices/postQueries/transactionQueriesPost";

const Checkout = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const subTotal = useSelector((state) => state.cart.subTotal);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [user, setUser] = useState({ token: null });
  const {data: userDetailsData, isLoading,error} = useGetMyDetailQuery();
  const {data: pincodeData, isLoading:pincodeLoading,error:pincodeError} = useGetPincodeDataQuery();
  const [PreTransaction, { isLoading:preTransactionLogin }] = usePreTransactionMutation();

  const fetchData = () => {  
      setName(userDetailsData?.name);
      setAddress(userDetailsData?.address);
      setPincode(userDetailsData?.pincode);
      setPhone(userDetailsData?.phone);
      getPincode(userDetailsData?.pincode);
  };
  useEffect(() => {
    const myuser = JSON.parse(localStorage.getItem("userInfo"));
    if (myuser) {
      setUser(myuser);
      setEmail(myuser.email);
      fetchData();
    }
  }, [userDetailsData]);

  useEffect(() => {
    if (
      name?.length > 2 &&
      email?.length > 2 &&
      address?.length > 2 &&
      phone?.length > 2 &&
      pincode?.length > 2
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name, email, phone, address, pincode]);

  const getPincode = (pin) => {
    if (pincodeData && Object.keys(pincodeData).includes(pin)) {
      setState(pincodeData[pin][1]);
      setCity(pincodeData[pin][0]);
    }
  };



  const handleChange = async (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "phone") {
      setPhone(e.target.value);
    } else if (e.target.name === "address") {
      setAddress(e.target.value);
    } else if (e.target.name === "city") {
      setCity(e.target.value);
    } else if (e.target.name === "pincode") {
      setPincode(e.target.value);
      if (e.target.value.length === 6) {
        getPincode(e.target.value);
      } else {
        setState("");
        setCity("");
      }

      getPincode(e.target.value);
    }
  };

  const initiatePayment = async () => {
    const data = {
      subTotal,
      cart,
      email,
      name,
      address,
      pincode,
      phone,
      city,
    };

    try{
      const res = await PreTransaction(data).unwrap();
      console.log(res);
      if(res.success){
        if(window.Razorpay) {
          var options = {
            key: "rzp_test_C3xTcPewrj9WaG", // Enter the Key ID generated from the Dashboard
            amount: subTotal, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Shapes 3d Test",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: res.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            callback_url: `http://localhost:5000${TRANSACTION_URL}/posttransaction`,
            prefill: {
              name: name,
              email: email,
              contact: phone,
            },
            notes: {
              address: "address",
            },
            theme: {
              color: "#3399cc",
            },
          };
          const razor = new window.Razorpay(options);
          razor.open();
        }
        else{
          console.error('Razorpay script not loaded.');
        }
      }else {
        if (res.cartClear) {
          clearCart();
        }
        console.log("i am here")
        toast.error(res.data.error);
      }
    }catch(error){
      console.log(error);
      toast.error(error.data?.error)
    }
  };

  return (
    <>
      <Helmet>
      <title>Checkout - shapes3d.in</title>
      <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <div className="py-10 bg-gray-100 flex flex-col px-6 md:px-14">
        <div className="maincontainer border-[1px] border-gray-200 p-4">
          <div className="container">
            <div>
              <h2 className="text-2xl my-3">Checkout</h2>
              <div className="bg-white border-[1px] border-gray-400 p-4 px-4 md:p-8">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                  <div className="">
                    <p className="font-medium text-lg">Delivery Details</p>
                    <p>Please Enter details carefully.</p>
                  </div>

                  <div className="lg:col-span-2">
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                      <div className="md:col-span-5">
                        <label htmlFor="name">Full Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={name}
                        />
                      </div>
                      {user && user.email ? (
                        <div className="md:col-span-5">
                          <label htmlFor="email">Email Address</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                            placeholder="email@domain.com"
                            value={user.email}
                            readOnly
                          />
                        </div>
                      ) : (
                        <div className="md:col-span-5">
                          <label htmlFor="email">Email Address</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                            placeholder="email@domain.com"
                            onChange={handleChange}
                            value={email}
                          />
                        </div>
                      )}

                      <div className="md:col-span-3">
                        <label htmlFor="address">Address / Street</label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={address}
                        />
                      </div>

                      <div className="md:col-span-2">
                        <label htmlFor="pincode">Pincode</label>
                        <input
                          type="number"
                          name="pincode"
                          id="pincode"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={pincode}
                        />
                      </div>

                      <div className="md:col-span-2">
                        <label htmlFor="phone">Phone</label>
                        <div className="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                          <input
                            type="number"
                            name="phone"
                            id="phone"
                            placeholder="Phone number"
                            className="h-10 px-4 appearance-none text-gray-800 w-full bg-transparent"
                            onChange={handleChange}
                            value={phone}
                          />
                        </div>
                      </div>

                      <div className="md:col-span-2">
                        <label htmlFor="state">State</label>
                        <div className="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                          <input
                            type="text"
                            name="state"
                            id="state"
                            placeholder=""
                            className="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent"
                            onChange={handleChange}
                            value={state}
                            readOnly={true}
                          />
                        </div>
                      </div>

                      <div className="md:col-span-1">
                        <label htmlFor="city">District</label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          className="transition-all outline-none flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          placeholder=""
                          onChange={handleChange}
                          value={city}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="reviewcart bg-white">
            <h2 className="text-2xl my-3">Review Cart Items & Pay</h2>

            <div className="reviewcart p-10 z-10 w-full border-[1px] border-gray-400">
              <h1 className="border-b-2 py-4 border-black text-xl">
                Shopping Bag
              </h1>
              <div className="wholecontainer flex flex-col">
                {Object.keys(cart).length === 0 && (
                  <div className="my-4 font-semibold">Your cart is empty. </div>
                )}
                {Object.keys(cart).map((k) => {
                  return (
                    <div
                      className="cart-product flex items-center text-[9px] md:text-lg md:justify-around border-b-2 border-black py-4"
                      key={k}
                    >
                      <div className="img h-20 md:h-36 overflow-hidden">
                        <img
                          src={`${cart[k].img}`}
                          alt="logo"
                          height={150}
                          width={150}
                          className="object-contain h-full m-auto"
                        />
                      </div>
                      <div className="right flex flex-col items-start">
                        <p>
                          {cart[k].name}({cart[k].size}/{cart[k].variant})
                        </p>
                        <p className="flex items-center">
                          Quantity:
                          <AiOutlineMinusSquare
                            onClick={() => {
                              dispatch(removeFromCart(
                                {
                                itemCode: k,
                                qty: 1,
                                price: cart[k].price,
                                name: cart[k].name,
                                size: cart[k].size,
                                variant: cart[k].variant,
                                img: cart[k].img
                                }
                              ))
                            }}
                            className="mx-2 cursor-pointer text-yellow-600"
                          />
                          {cart[k].qty}
                          <AiOutlinePlusSquare
                            onClick={() => {
                              dispatch(addToCart(
                                {
                                itemCode: k,
                                qty: 1,
                                price: cart[k].price,
                                name: cart[k].name,
                                size: cart[k].size,
                                variant: cart[k].variant,
                                img: cart[k].img
                              }
                              ))
                            }}
                            className="mx-2 cursor-pointer text-yellow-600"
                          />
                        </p>
                        <p>₹{cart[k].price}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="subtotal flex justify-between mt-3">
                <h3 className="font-bold">Subtotal</h3>
                <h3>₹{subTotal}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons flex mt-8 gap-6 justify-start">
          <Link href={"/checkout"}>
            <button
              disabled={disabled}
              onClick={() => {
                initiatePayment(subTotal);
              }}
              className="flex text-white disabled:bg-yellow-300 bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-md"
            >
              ₹ Pay
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Checkout;




// ****************************next js code *********************************
// import React from "react";
// import { useState, useEffect } from "react";
// import Link from "next/link";
// import Head from "next/head";
// import Script from "next/script";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   AiOutlinePlusSquare,
//   AiOutlineMinusSquare,
//   AiFillCloseSquare,
// } from "react-icons/ai";

// const Checkout = ({ cart, addToCart, removeFromCart, clearCart, subTotal }) => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [state, setState] = useState("");
//   const [city, setCity] = useState("");
//   const [phone, setPhone] = useState("");
//   const [address, setAddress] = useState("");
//   const [pincode, setPincode] = useState("");
//   const [disabled, setDisabled] = useState(true);
//   const [user, setUser] = useState({ token: null });
//   useEffect(() => {
//     const myuser = JSON.parse(localStorage.getItem("myuser"));
//     if (myuser && myuser.token) {
//       setUser(myuser);
//       setEmail(myuser.email);
//       fetchData(myuser.token);
//     }
//   }, []);

//   useEffect(() => {
//     if (
//       name.length > 2 &&
//       email.length > 2 &&
//       address.length > 2 &&
//       phone.length > 2 &&
//       pincode.length > 2
//     ) {
//       setDisabled(false);
//     } else {
//       setDisabled(true);
//     }
//   }, [name, email, phone, address, pincode]);

//   const getPincode = async (pin) => {
//     let pins = await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/pincode`);
//     let pinJson = await pins.json();
//     if (Object.keys(pinJson).includes(pin)) {
//       setState(pinJson[pin][1]);
//       setCity(pinJson[pin][0]);
//     }
//   };

//   const fetchData = async (token) => {
//     let data = { token: token };
//     const a = await fetch(`${process.env.NEXT_PUBLIC_HOST}/api/getuser`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     });
//     const b = await a.json();
//     setName(b.name);
//     setAddress(b.address);
//     setPincode(b.pincode);
//     setPhone(b.phone);
//     getPincode(b.pincode);
//   };

//   const handleChange = async (e) => {
//     if (e.target.name == "name") {
//       setName(e.target.value);
//     } else if (e.target.name == "email") {
//       setEmail(e.target.value);
//     } else if (e.target.name == "phone") {
//       setPhone(e.target.value);
//     } else if (e.target.name == "address") {
//       setAddress(e.target.value);
//     } else if (e.target.name == "city") {
//       setCity(e.target.value);
//     } else if (e.target.name == "pincode") {
//       setPincode(e.target.value);
//       if (e.target.value.length == 6) {
//         getPincode(e.target.value);
//       } else {
//         setState("");
//         setCity("");
//       }

//       getPincode(e.target.value);
//     }
//   };

//   const initiatePayment = async () => {
//     const data = {
//       subTotal,
//       cart,
//       email,
//       name,
//       address,
//       pincode,
//       phone,
//       city,
//     };

//     const a = await fetch(
//       `${process.env.NEXT_PUBLIC_HOST}/api/pretransaction`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }
//     );
//     const b = await a.json();
//     if (b.success) {
//       var options = {
//         key: process.env.NEXT_PUBLIC_RAZOR_KEY_ID, // Enter the Key ID generated from the Dashboard
//         amount: subTotal, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
//         currency: "INR",
//         name: "Shapes 3d Test",
//         description: "Test Transaction",
//         image: "https://example.com/your_logo",
//         order_id: b.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
//         callback_url: `${process.env.NEXT_PUBLIC_HOST}/api/posttransaction`,
//         prefill: {
//           name: name,
//           email: email,
//           contact: phone,
//         },
//         notes: {
//           address: "address",
//         },
//         theme: {
//           color: "#3399cc",
//         },
//       };
//       const razor = new Razorpay(options);
//       razor.open();
//     } else {
//       if (b.cartClear) {
//         clearCart();
//       }
//       toast.error(b.error, {
//         position: "top-left",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//     }
//   };
//   return (
//     <>
//       <Head>
//         <title>Checkout - shapes3d.in</title>
//       </Head>
//       <Script src="https://checkout.razorpay.com/v1/checkout.js"></Script>
//       <div className="py-10 bg-gray-100 flex flex-col px-6 md:px-14">
//         <div className="maincontainer border-[1px] border-gray-200 p-4">
//           <div className="container">
//             <ToastContainer
//               position="top-left"
//               autoClose={5000}
//               hideProgressBar={false}
//               newestOnTop={false}
//               closeOnClick
//               rtl={false}
//               pauseOnFocusLoss
//               draggable
//               pauseOnHover
//               theme="light"
//             />
//             <div>
//               <h2 className="text-2xl my-3">Checkout</h2>
//               <div className="bg-white border-[1px] border-gray-400 p-4 px-4 md:p-8">
//                 <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
//                   <div className="">
//                     <p className="font-medium text-lg">Delivery Details</p>
//                     <p>Please Enter details carefully.</p>
//                   </div>

//                   <div className="lg:col-span-2">
//                     <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
//                       <div className="md:col-span-5">
//                         <label htmlFor="name">Full Name</label>
//                         <input
//                           type="text"
//                           name="name"
//                           id="name"
//                           className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
//                           onChange={handleChange}
//                           value={name}
//                         />
//                       </div>
//                       {user && user.email ? (
//                         <div className="md:col-span-5">
//                           <label htmlFor="email">Email Address</label>
//                           <input
//                             type="email"
//                             name="email"
//                             id="email"
//                             className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
//                             placeholder="email@domain.com"
//                             value={user.email}
//                             readOnly
//                           />
//                         </div>
//                       ) : (
//                         <div className="md:col-span-5">
//                           <label htmlFor="email">Email Address</label>
//                           <input
//                             type="email"
//                             name="email"
//                             id="email"
//                             className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
//                             placeholder="email@domain.com"
//                             onChange={handleChange}
//                             value={email}
//                           />
//                         </div>
//                       )}

//                       <div className="md:col-span-3">
//                         <label htmlFor="address">Address / Street</label>
//                         <input
//                           type="text"
//                           name="address"
//                           id="address"
//                           className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
//                           onChange={handleChange}
//                           value={address}
//                         />
//                       </div>

//                       <div className="md:col-span-2">
//                         <label htmlFor="pincode">Pincode</label>
//                         <input
//                           type="number"
//                           name="pincode"
//                           id="pincode"
//                           className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
//                           onChange={handleChange}
//                           value={pincode}
//                         />
//                       </div>

//                       <div className="md:col-span-2">
//                         <label htmlFor="phone">Phone</label>
//                         <div className="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
//                           <input
//                             type="number"
//                             name="phone"
//                             id="phone"
//                             placeholder="Phone number"
//                             className="h-10 px-4 appearance-none text-gray-800 w-full bg-transparent"
//                             onChange={handleChange}
//                             value={phone}
//                           />
//                         </div>
//                       </div>

//                       <div className="md:col-span-2">
//                         <label htmlFor="state">State</label>
//                         <div className="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
//                           <input
//                             type="text"
//                             name="state"
//                             id="state"
//                             placeholder=""
//                             className="px-4 appearance-none outline-none text-gray-800 w-full bg-transparent"
//                             onChange={handleChange}
//                             value={state}
//                             readOnly={true}
//                           />
//                         </div>
//                       </div>

//                       <div className="md:col-span-1">
//                         <label htmlFor="city">District</label>
//                         <input
//                           type="text"
//                           name="city"
//                           id="city"
//                           className="transition-all outline-none flex items-center h-10 border mt-1 rounded px-4 w-full bg-gray-50"
//                           placeholder=""
//                           onChange={handleChange}
//                           value={city}
//                           readOnly={true}
//                         />
//                       </div>

//                       {/* <div className="md:col-span-5 text-right">
//                       <div className="inline-flex items-end">
//                         <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//                           Submit
//                         </button>
//                       </div>
//                     </div> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="reviewcart bg-white">
//             <h2 className="text-2xl my-3">Review Cart Items & Pay</h2>

//             <div className="reviewcart p-10 z-10 w-full border-[1px] border-gray-400">
//               <h1 className="border-b-2 py-4 border-black text-xl">
//                 Shopping Bag
//               </h1>
//               <div className="wholecontainer flex flex-col">
//                 {Object.keys(cart).length == 0 && (
//                   <div className="my-4 font-semibold">Your cart is empty. </div>
//                 )}
//                 {Object.keys(cart).map((k) => {
//                   return (
//                     <div
//                       className="cart-product flex items-center text-[9px] md:text-lg md:justify-around border-b-2 border-black py-4"
//                       key={k}
//                     >
//                       <div className="img h-20 md:h-36 overflow-hidden">
//                         <img
//                           src={`${cart[k].img}`}
//                           alt="logo"
//                           height={150}
//                           width={150}
//                           className="object-contain h-full m-auto"
//                         />
//                       </div>
//                       <div className="right flex flex-col items-start">
//                         <p>
//                           {cart[k].name}({cart[k].size}/{cart[k].variant})
//                         </p>
//                         <p className="flex items-center">
//                           Quantity:
//                           <AiOutlineMinusSquare
//                             onClick={() => {
//                               removeFromCart(
//                                 k,
//                                 1,
//                                 cart[k].price,
//                                 cart[k].name,
//                                 cart[k].size,
//                                 cart[k].variant
//                               );
//                             }}
//                             className="mx-2 cursor-pointer text-yellow-600"
//                           />
//                           {cart[k].qty}
//                           <AiOutlinePlusSquare
//                             onClick={() => {
//                               addToCart(
//                                 k,
//                                 1,
//                                 cart[k].price,
//                                 cart[k].name,
//                                 cart[k].size,
//                                 cart[k].variant
//                               );
//                             }}
//                             className="mx-2 cursor-pointer text-yellow-600"
//                           />
//                         </p>
//                         <p>₹{cart[k].price}</p>
//                       </div>
//                     </div>
//                   );
//                 })}
//               </div>
//               <div className="subtotal flex justify-between mt-3">
//                 <h3 className="font-bold">Subtotal</h3>
//                 <h3>₹{subTotal}</h3>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="buttons flex mt-8 gap-6 justify-start">
//           <Link href={"/checkout"}>
//             <button
//               disabled={disabled}
//               onClick={() => {
//                 initiatePayment(subTotal);
//               }}
//               className="flex text-white disabled:bg-yellow-300 bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-md"
//             >
//               ₹ Pay
//             </button>
//           </Link>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Checkout;

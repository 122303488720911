import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    cart: {},
    subTotal: 0,
}

const calculateSubTotal = (cart) => {
  let subtotal = 0;
  if (cart) {
    for (const itemCode in cart) {
      subtotal += cart[itemCode].price * cart[itemCode].qty;
    }
  }
  return subtotal.toFixed(2);
};


const loadedCart = localStorage.getItem('cart');
if (loadedCart) {
  initialState.cart = JSON.parse(loadedCart);
  initialState.subTotal = calculateSubTotal(initialState.cart);
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const { itemCode, qty, price, name, size, variant, img } = action.payload;
            if (itemCode in state.cart) {
              state.cart[itemCode].qty += qty;
            } else {
              state.cart[itemCode] = { qty, price, name, size, variant, img };
            }
            state.subTotal = calculateSubTotal(state.cart);
            localStorage.setItem('cart', JSON.stringify(state.cart));
          },
          removeFromCart: (state, action) => {
            const { itemCode, qty } = action.payload;
            const newCart = { ...state.cart };
      
            if (itemCode in newCart) {
              newCart[itemCode].qty -= qty;
      
              if (newCart[itemCode].qty <= 0) {
                delete newCart[itemCode];
              }
            }
      
            state.cart = newCart;
            state.subTotal = calculateSubTotal(newCart);
            localStorage.setItem('cart', JSON.stringify(newCart));
          },
          clearCart: (state) => {
            state.cart = {};
            state.subTotal = 0;
            localStorage.removeItem('cart');
          },
          buyNow: (state, action) => {
            const { itemCode, qty, price, name, size, variant, img } = action.payload;
            const newCart = { ...state.cart };
      
            if (itemCode in newCart) {
              newCart[itemCode].qty += qty;
            } else {
              newCart[itemCode] = { qty, price, name, size, variant, img };
            }
      
            state.cart = newCart;
            state.subTotal = calculateSubTotal(newCart);
          },
          initializeCart: (state, action) => {
            const loadedCart = localStorage.getItem('cart');
            state.cart = loadedCart ? JSON.parse(loadedCart) : {};
            state.subTotal = calculateSubTotal(state.cart); 
        },
    }
})


export const { addToCart, removeFromCart, clearCart, initializeCart } = cartSlice.actions;
export default cartSlice.reducer;
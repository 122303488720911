import React from 'react'

const ReviewCard = ({addClasses}) => {
  return (
    <div className={`h-72 rounded-lg mx-10 bg-white ${addClasses}`}>
        Hello World
    </div>
  )
}

export default ReviewCard
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { Link,useLocation,useNavigate } from 'react-router-dom';
import { useConfirmpasswordmailMutation } from "../slices/postQueries/authQueriesPost";
import Loader from "../components/Loader";

const ResetPassword = () => {
  const [confirmpasswordmail, { isLoading,error }] = useConfirmpasswordmailMutation();
  const [cpassword, setCpassword] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      navigate("/");
    }
  }, [navigate]);



  const handleChange = (e) => {
    if (e.target.name == "cpassword") {
      setCpassword(e.target.value);
    } else if (e.target.name == "password") {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const tokenFromQuery = searchParams.get('token');
    const data = { token: tokenFromQuery, password };
    if (password == cpassword) {
      try {
        const res = await confirmpasswordmail(data).unwrap();
        if(res.success){
          toast.success("Password has been updated successfully");
        }else{
          toast.error("Error Occured while updating password!");
        }
      } catch (err) {
        console.log(err);
        toast.error(err?.data?.message || err.data.error);
      }
    } else {
      toast.error("password and confim password do not match")
  }
}
  return (
    <>
     {
      isLoading? (
        <Loader />
      ): (
        <div>
        <section className="bg-gray-50">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a
              href="#"
              className="flex items-center mb-6 text-2xl font-semibold text-gray-900 "
            >
              <img
                className="w-36 md:w-56 h-8 md:h-14"
                src="/images/logo/shapes3dlogo.png"
                alt="logo"
              />
            </a>
            <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0  ">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                  Reset Your Account Password
                </h1>
                <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Password
                    </label>
                    <input
                      value={password}
                      onChange={handleChange}
                      type="password"
                      name="password"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5  "
                      placeholder="••••••••"
                      required=""
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Confirm Password
                    </label>
                    <input
                      value={cpassword}
                      onChange={handleChange}
                      type="password"
                      name="cpassword"
                      id="cpassword"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5"
                      required=""
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-yellow-600 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      )
     }
    </>
   
  );
};

export default ResetPassword;

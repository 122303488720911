import React from "react";
// import { useLocation,useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGetMyDetailQuery } from "../slices/getQueries/userQueriesGet";
// import { useDispatch } from 'react-redux';
import { useUpdateMyDetailMutation,useUpdateMyPasswordMutation } from "../slices/postQueries/userQueriesPost";
import Loader from "../components/Loader";

const MyAccount = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [user, setUser] = useState({ token: null });
  const [password, setPassword] = useState("");
  const [npassword, setNpassword] = useState("");
  const [cnpassword, setCnpassword] = useState("");
  const {data: userDetailsData, isLoading,error} = useGetMyDetailQuery();
  const [updateMyDetail,{isLoading: updateMyDetailLoader, error: updateMyDetailError}] = useUpdateMyDetailMutation();
  const [updateMyPassword,{isLoading: updateMyPasswordLoader, error: updateMyPasswordError}] = useUpdateMyPasswordMutation();

  useEffect(() => {
    const myuser = JSON.parse(localStorage.getItem("userInfo"));
    if (myuser && myuser.email) {
      setUser(myuser);
      setEmail(myuser.email);
      fetchData();
    }
  }, [userDetailsData]);

  const fetchData = () => {
    setName(userDetailsData?.name);
    setAddress(userDetailsData?.address);
    setPincode(userDetailsData?.pincode);
    setPhone(userDetailsData?.phone);
  };

  const handleUserSubmit = async () => {
    let data = { token: user.token, name, address, phone, pincode };
    try{
      const res = await updateMyDetail(data);
      if(res.data.success){
        console.log("sucess")
        toast.success("Successfully Updated Details");
      }
    }catch(error){
      toast.error(error?.error||error.message);
    }
  };

  const handlePasswordSubmit = async () => {
    if (npassword === cnpassword) {
      let data = { password, npassword, cnpassword };
      try{
        const res = await updateMyPassword(data);
        if(res.data.success){
          toast.success("Successfully Updated Password");
        }else{
          toast.error("Error while updating password!");
        }
      }catch(error){
        toast.error(error?.error||error.message);
      }
    } 
    setPassword("");
    setNpassword("");
    setCnpassword("");
  };
  const handleChange = async (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "phone") {
      setPhone(e.target.value);
    } else if (e.target.name === "address") {
      setAddress(e.target.value);
    } else if (e.target.name === "pincode") {
      setPincode(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "npassword") {
      setNpassword(e.target.value);
    } else if (e.target.name === "cnpassword") {
      setCnpassword(e.target.value);
    }
  };

  return (
    <>
    {
      isLoading || updateMyDetailLoader || updateMyPasswordLoader ? (
        <Loader/>
      ): error || updateMyDetailError || updateMyPasswordError  ? (
        <div>Error loading. Please try again later.</div>
      ) : (
        <div className="py-10 bg-gray-100 flex flex-col px-6 md:px-14">
        <div className="maincontainer border-[1px] border-gray-200 p-4">
          <div className="container">
            <h2 className="text-2xl my-3">Your Account Details</h2>
            <div className="container flex flex-col gap-y-5">
              <div className="bg-white border-[1px] border-gray-400 p-4 px-4 md:p-8">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                  <div className="">
                    <p className="font-medium text-lg">1.Delivery Details</p>
                    <p>Please Enter details carefully.</p>
                  </div>

                  <div className="lg:col-span-2">
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                      <div className="md:col-span-5">
                        <label htmlFor="name">Full Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={name}
                        />
                      </div>
                      {user && user.email ? (
                        <div className="md:col-span-5">
                          <label htmlFor="email">
                            Email (Can not be updated)
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                            placeholder="email@domain.com"
                            value={user.email}
                            readOnly
                          />
                        </div>
                      ) : (
                        <div className="md:col-span-5">
                          <label htmlFor="email">Email Address</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                            placeholder="email@domain.com"
                            onChange={handleChange}
                            value={email}
                          />
                        </div>
                      )}

                      <div className="md:col-span-3">
                        <label htmlFor="address">Address / Street</label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={address}
                        />
                      </div>

                      <div className="md:col-span-2">
                        <label htmlFor="pincode">Pincode</label>
                        <input
                          type="number"
                          name="pincode"
                          id="pincode"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={pincode}
                        />
                      </div>

                      <div className="md:col-span-2">
                        <label htmlFor="phone">Phone</label>
                        <div className="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                          <input
                            type="number"
                            name="phone"
                            id="phone"
                            placeholder="Phone number"
                            className="h-10 px-4 appearance-none text-gray-800 w-full bg-transparent"
                            onChange={handleChange}
                            value={phone}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={handleUserSubmit}
                      className="mt-3 flex text-white disabled:bg-yellow-300 bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>

              <div className="bg-white border-[1px] border-gray-400 p-4 px-4 md:p-8">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                  <div className="">
                    <p className="font-medium text-lg">2.Change Password</p>
                    <p>Please Enter details carefully.</p>
                  </div>

                  <div className="lg:col-span-2">
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                      <div className="md:col-span-5">
                        <label htmlFor="password">Old Password</label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={password}
                        />
                      </div>

                      <div className="md:col-span-2">
                        <label htmlFor="npassword">New Password</label>
                        <input
                          type="password"
                          name="npassword"
                          id="npassword"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={npassword}
                        />
                      </div>
                      <div className="md:col-span-3">
                        <label htmlFor="Cnpassword">Confirm New Password</label>
                        <input
                          type="password"
                          name="cnpassword"
                          id="cnpassword"
                          className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                          onChange={handleChange}
                          value={cnpassword}
                        />
                      </div>
                    </div>
                    <button
                      onClick={handlePasswordSubmit}
                      className="mt-3 flex text-white disabled:bg-yellow-300 bg-yellow-500 border-0 py-2 px-6 focus:outline-none hover:bg-yellow-600 rounded text-md"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ) 
    }
    </>
  );
};

export default MyAccount;

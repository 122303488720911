import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import particlesConfig from "../config/particlesConfig";
import Particles from "react-tsparticles";
import { useSubscribeMutation } from "../slices/postQueries/subscriptionQueries";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  background-color: #ffffff;
  animation: ${fadeIn} 1s ease-in;
  z-index: 1;
`;

const Logo = styled.img`
  width: 600px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 50px;
  color: #f1c406;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 600;
  font-style: normal;
`;

const Subtitle = styled.p`
  font-size: 30px;
  margin: 10px 0;
  color: #333;
  font-family: "Manrope", sans-serif;
`;

const Form = styled.form`
  display: flex;
  gap: 1rem;
  width: 100%;
  max-width: 500px;
  margin: 1rem 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const StyledButton = styled.button`
  padding: 0.75rem 2rem;
  background-color: #f1c406;
  color: #1a1a1a;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Inter", sans-serif;
  white-space: nowrap;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: #1a1a1a;
    color: #f1c406;
    transform: translateY(-1px);
    box-shadow: 0 4px 15px rgba(241, 196, 6, 0.2);
  }

  &:active {
    transform: translateY(0);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 0.75rem 1rem;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  outline: none;
  font-family: "Inter", sans-serif;

  &:focus {
    border-color: #f1c406;
    box-shadow: 0 0 0 3px rgba(241, 196, 6, 0.1);
  }

  &::placeholder {
    color: #999;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  animation: ${fadeIn} 1.2s ease-out;
  max-width: 90%;
  width
`;

const ParticlesBackground = styled(Particles)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const fadeInScale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const SuccessMessage = styled.div`
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  color: #1a1a1a;
  text-align: center;
  animation: ${fadeInScale} 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
`;
export default function ComingSoon() {
  const [isMounted, setIsMounted] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subscribe, { isLoading, isSuccess, isError, error }] =
    useSubscribeMutation();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await subscribe(email).unwrap();
      setMessage(response.message);
    } catch (err) {
      console.error("Failed to subscribe:", err);
    }
  };

  return (
    <Container>
      {isMounted && <ParticlesBackground options={particlesConfig} />}
      <ContentWrapper>
        <Logo src="/images/logo/shapes3d.png" alt="Shapes3D Logo" />
        <Title>Coming Soon!</Title>
        <Subtitle>Get Notified When We Launch</Subtitle>
        <Form onSubmit={handleSubmit}>
          <StyledInput
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <StyledButton type="submit" disabled={isLoading}>
            {isLoading ? "...." : "Notify Me"}
          </StyledButton>
        </Form>

        {isSuccess && (
          <SuccessMessage>
            {message || "Thanks! We'll notify you when we launch."}
          </SuccessMessage>
        )}
        {isError && (
          <SuccessMessage style={{ color: "red" }}>
            Error: {error?.data?.message || "Something went wrong"}
          </SuccessMessage>
        )}
      </ContentWrapper>
    </Container>
  );
}

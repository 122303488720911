import { apiSlice } from "../apislice";
import { ORDER_URL } from "../../constants"; 

export const orderApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) =>({
        getMyOrders: builder.query({
            query: () =>{
                return {
                    url: `${ORDER_URL}/myOrders`,
                    credentials: "include",
                    withCredentials: true,
                }
            },
            keepUnusedDataFor: 5,
        })
    })
})

export const {useGetMyOrdersQuery} = orderApiSlice
import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/apislice";
import authsliceReducer from "./slices/authslice";
import cartsliceReducer from "./slices/cartslice";


const store = configureStore({
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      auth: authsliceReducer,
      cart: cartsliceReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true,
  });
  
export { store };

import { apiSlice } from "../apislice";
import { PRODUCT_URL } from "../../constants";

export const ProductApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) =>({
        getProducts: builder.query({
            query: () =>{
                return {
                    url: `${PRODUCT_URL}/getProducts`,
                }
            },
            keepUnusedDataFor: 5,
        }),
        getProductWithId: builder.query({
            query: ({id}) =>{
                return {
                    url: `${PRODUCT_URL}/getProductWithId/${id}`,
                }
            },
            keepUnusedDataFor: 5,
        })
    })
})

export const {useGetProductsQuery,useGetProductWithIdQuery} = ProductApiSlice;
import React from 'react';
import ImageSlider from "../components/ImageSlider";
import ParticlesComponent from '../components/ParticlesComponent';
import Slider from '../components/Slider';
import { Link,useNavigate,NavLink } from 'react-router-dom';
import { MdPadding } from 'react-icons/md';

export default function Home() {

  const images = [
    { url: "./images/homeimg/ghamla.png" },
    { url: "./images/homeimg/image2-engine-homefront.png" },
    { url: "./images/homeimg/image3-roboticarm-homefront.png" },
    { url: "./images/homeimg/image4-printerimage-homefront.png" },
  ];

  return (
    <>
      <div className="w-full grid grid-cols-4 py-10 gap-y-20">
        <div className="firstcontainer px-6 md:px-36 col-span-4 grid grid-cols-4 items-center gap-y-10 md:h-[78vh] justify-self-center md:justify-self-auto">
          <div className="left-container col-span-4 md:col-span-2">
            <h2 className="font-bold text-lg md:text-xl drop-shadow-sm">
              Convert your Dreams <br /> into Reality with custom
            </h2>
            <h1 className="text-5xl md:text-6xl font-bold text-[#FFC200] drop-shadow-lg">
              3D PRINTING
            </h1>
            <p className="font-semibold mt-8 text-xs md:text-sm">
              Get an instant 3D printing quote today. On-demand 3D printing for
            </p>
            <p className="text-[#FFC200] text-sm md:text-md font-semibold">
              Rapid Prototyping, Production
              <span className="text-black"> & </span> Gifts
            </p>
            <div className="buttons mt-12">
              <button className="bg-[#F5C839] hover:bg-yellow-300 text-white text-sm font-semibold h-10 w-36 shadow-xl rounded-3xl">
                Upload Design
              </button>
              <button className="bg-white hover:bg-gray-100 text-black text-sm font-semibold h-10 w-36 shadow-xl rounded-3xl ml-6">
                <NavLink to="/contact">Contact Us</NavLink>
              </button> 
            </div>
          </div>
          <div className="right-container justify-self-center md:justify-self-end col-span-4 md:col-span-2">
            <div className="h-64 w-52 md:h-[22rem] md:w-96">
              <ImageSlider slides={images} parentWidth={13} />
            </div>
          </div>
        </div>
        <div className="secondcontainer px-6 md:px-36 col-span-4 grid justify-items-center">
          <div className="maincontainer md:w-[50vw] border-dashed border-4 border-[#FFC200] rounded-3xl shadow-2xl p-6 flex flex-col items-center gap-y-8">
            <div>
              <h1 className="text-2xl md:text-3xl font-bold text-center">
                Upload your 3D CAD File
              </h1>
              <p className="text-[#909090] text-center text-[0.60rem] md:text-[0.80rem]">
                Don’t have one! We will design it for you
                <span>
                  {" "}
                  <a href="/" className="text-[#FFC200]">
                    Click Here
                  </a>{" "}
                </span>
              </p>
            </div>
            <img
              src="./images/homeimg/ganda-second-section.png"
              alt="ganda png"
              className="h-32 w-32 md:h-44 md:w-48"
            />
            <button className="bg-[#F5C839] hover:bg-yellow-300 text-white text-md font-semibold h-12 w-40 shadow-xl rounded-3xl">
              Upload File
            </button>
            <p className="text-sm">
              Upload STL files ony.{" "}
              <a href="/" className="text-[#0047FF]">
                Learn More
              </a>
            </p>
          </div>
        </div>
        <div className="thirdcontainer px-6 md:px-36 col-span-4">
          <div className="whyshapes3d grid grid-cols-2 md:grid-cols-4 gap-y-4">
            <h2 className="text-black text-3xl font-bold col-span-2 md:col-span-4 justify-self-center">
              Why <span className="text-[#FFC200]">Shapes3D</span> ?
            </h2>
            <div className="firstfeature flex flex-col items-center">
              <img
                src="./images/homeimg/3rd-section-bijli.png"
                alt="bijli png"
                className="h-32 w-32 md:h-40 md:w-40"
              />
              <h3 className="font-bold text-md">Fast Service</h3>
              <p className="text-xs">24 hr Turnaround Time</p>
            </div>
            <div className="secondfeature flex flex-col items-center">
              <img
                src="/images/homeimg/3rd-section-paisa.png"
                alt="paisa png"
                className="h-32 w-32 md:h-40 md:w-40"
              />
              <h3 className="font-bold text-md">Super Affordable</h3>
              <p className="text-xs">Rs. 10/gm</p>
            </div>
            <div className="thirdfeature flex flex-col items-center">
              <img
                src="/images/homeimg/3rd-section-thumbs-up.png"
                alt="thumbs up png"
                className="h-32 w-32 md:h-40 md:w-40"
              />
              <h3 className="font-bold text-md">Best Quality</h3>
              <p className="text-xs">Industry Standards</p>
            </div>
            <div className="fourthfeature flex flex-col items-center">
              <img
                src="/images/homeimg/3rd-section-headphones.png"
                alt="headphone png"
                className="h-32 w-32 md:h-40 md:w-40"
              />
              <h3 className="font-bold text-md">Customer Support</h3>
              <p className="text-xs">24x7 Support</p>
            </div>
          </div>
        </div>
        {/* <div className="fourthcontainer px-6 md:px-36 col-span-4"> */}
          {/* <div className="printedproducts grid grid-cols-4">
            <h2 className="text-xl font-bold justify-self-center col-span-4">
              Shop
            </h2>
            <h3 className="text-3xl font-bold text-[#FFC200] col-span-4 justify-self-center">
              3D Printed Products
            </h3>
            <div className="showingproducts h-20 text-3xl col-span-4 justify-self-center">
              Here I will add products
            </div>
          </div> */}
        {/* </div> */}
        <div className="fifthcontainer px-6 md:px-36 col-span-4">
          <div className="best3dprinting grid grid-cols-4 gap-y-5 md:gap-y-0">
            <div className="best3dprintingleft col-span-4 md:col-span-2 justify-self-center">
              <img
                src="/images/homeimg/5th-section-3d-printinig.png"
                alt="best 3d printing img"
                className="h-[22rem] w-96 pb-4"
              />
            </div>
            <div className="best3dprintingright col-span-4 md:col-span-2 flex flex-col gap-y-4 justify-self-center justify-center">
              <div className="heading">
                <h3 className="text-xl font-bold">We offer</h3>
                <h1 className="text-3xl text-[#FFC200] font-semibold drop-shadow-lg">
                  Best 3D Printing Services
                </h1>
              </div>
              <div className="points">
                <p>&#9670; Cost-Effective</p>
                <p>&#9670; High Quality Prints</p>
                <p>&#9670; Easy Customization</p>
                <p>&#9670; Prototyping</p>
                <p>&#9670; Variety of materials</p>
              </div>
              <div className="buttons">
                <button className="bg-[#F5C839] hover:bg-yellow-300 text-white text-sm font-semibold h-10 w-36 shadow-xl rounded-3xl">
                  Get Quote
                </button>
                <button className="bg-white hover:bg-gray-100 text-black text-sm font-semibold h-10 w-36 shadow-xl rounded-3xl ml-6">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="sixthcontainer px-6 md:px-36 col-span-4 flex flex-col gap-y-8 items-center">
          <h1 className="text-4xl font-bold text-[#FFC200] text-center">
            Our Services
          </h1>
          <div className="ourservices grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 justify-between md:gap-x-24 gap-y-6 font-semibold text-lg relative">
          {/* <div className="ourservices grid grid-cols-1 md:grid-cols-2 gap-x-72 justify-items-center font-semibold text-lg gap-y-8"> */}
            <div className="service1 flex flex-col items-center gap-y-4 shadow-lg p-4 transition-transform transform hover:-translate-y-2 hover:shadow-xl">
              <img
                src="/images/ourservices/3d_designing.jpeg"
                alt="services img"
                className="h-44 w-44 object-cover"
              />
              <h3>3D Designing</h3>
            </div>
            <div className="service1 flex flex-col items-center gap-y-4 shadow-lg p-4 transition-transform transform hover:-translate-y-2 hover:shadow-xl">
              <img
                src="/images/ourservices/3dprinting.jpeg"
                alt="services img"
                className="h-44 w-44 object-cover"
              />
              <h3>3D Printing</h3>
            </div>
            <div className="service1 flex flex-col items-center gap-y-4 shadow-lg p-4 transition-transform transform hover:-translate-y-2 hover:shadow-xl">
              <img
                src="/images/ourservices/prototyping.jpeg"
                alt="services img"
                className="h-44 w-44 object-cover"
              />
              <h3>Product Prototyping</h3>
            </div>
            <div className="service1 flex flex-col items-center gap-y-4 shadow-lg p-4 transition-transform transform hover:-translate-y-2 hover:shadow-xl">
              <img
                src="/images/ourservices/consultancy.jpeg"
                alt="services img"
                className="h-44 w-44 object-cover"
              />
              <h3>Design Consultancy</h3>
            </div>
            {/* <div className="service1 flex flex-col items-center gap-y-4">
              <img
                src="/images/homeimg/6th-section-services.png"
                alt="services img"
                className="h-44 w-44"
              />
              <h3>3d Printed kits</h3>
            </div> */}
            {/* <div className="service1 flex flex-col items-center gap-y-4">
              <img
                src="/images/homeimg/6th-section-services.png"
                alt="services img"
                className="h-44 w-44"
              />
              <h3>Ready to sale products</h3>
            </div> */}
          </div>
        </div>
        {/* <div className="seventhcontainer px-6 md:px-36 col-span-4 flex flex-col">
          <h3 className="text-center font-bold text-xl">Super Simple</h3>
          <h1 className="text-center font-bold text-4xl text-[#FFC200] drop-shadow-lg">
            Design Process
          </h1>
          <div className="allimagecontainer text-center">
            This will be implemented later!
          </div>
        </div> */}
        <div className="eighthcontainer px-6 md:px-36 col-span-4">
          <div className="consultdesigner grid grid-cols-1 md:grid-cols-2 justify-items-center gap-y-14 md:gap-y-0">
            <div className="leftcontainer flex flex-col">
              <h2 className="text-2xl font-bold">Need a 3D Designer?</h2>
              <h1 className="text-3xl md:text-4xl font-bold text-[#FFC200] mb-6">
                Consult with our designer !
              </h1>
              <ul>
                <li>&#9670; 3D design that represents your vision</li>
                <li>&#9670; Get a professional touch</li>
                <li>&#9670; Save time</li>
                <li>&#9670; Unlimited Revisions</li>
                <li>&#9670; Cost-Effective</li>
              </ul>
              <div className="buttons mt-12">
                <button className="bg-[#F5C839] hover:bg-yellow-300 text-white text-md font-semibold h-10 w-36 shadow-xl rounded-3xl">
                  Consult Now
                </button>
                <button className="bg-white hover:bg-gray-100 text-black text-md font-semibold h-10 w-36 shadow-xl rounded-3xl ml-6">
                  Get Quote
                </button>
              </div>
            </div>
            <div className="rightcontainer">
              <img
                src="/images/homeimg/8th-section-consult-with-designer.png"
                alt="designer"
                className="h-80"
              />
            </div>
          </div>
        </div>
        <div className="ninthcontainer col-span-4 relative bg-yellow-300 flex flex-col items-center p-6">
          <ParticlesComponent/>
          <h2 className='text-xl md:text-2xl font-bold text-white'>Listen</h2>
          <h3 className='text-2xl md:text-3xl font-semibold'>What Our Customer Says</h3>
          <Slider addClasses={"w-full mt-10"}/>
        </div>
        <div className="tenthcontainer px-6 md:px-36 col-span-4 grid justify-items-center">
          <div className="maincontainer md:w-[50vw] border-dashed border-4 border-[#FFC200] rounded-3xl shadow-2xl p-6 flex flex-col items-center gap-y-8">
            <div>
              <h1 className="text-2xl md:text-3xl font-bold text-center">
                Upload your 3D CAD File
              </h1>
              <p className="text-[#909090] text-center text-[0.60rem] md:text-[0.80rem]">
                Don’t have one! We will design it for you
                <span>
                  {" "}
                  <a href="/" className="text-[#FFC200]">
                    Click Here
                  </a>{" "}
                </span>
              </p>
            </div>
            <img
              src="/images/homeimg/ganda-second-section.png"
              alt="ganda png"
              className="h-32 w-32 md:h-44 md:w-48"
            />
            <button className="bg-[#F5C839] hover:bg-yellow-300 text-white text-md font-semibold h-12 w-40 shadow-xl rounded-3xl">
              Upload File
            </button>
            <p className="text-sm">
              Upload STL files only.{" "}
              <a href="/" className="text-[#0047FF]">
                Learn More
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}


import { apiSlice } from "../apislice";
import { USERS_URL } from "../../constants"; 


export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) =>({
        getMyDetail: builder.query({
            query: () =>{
                return {
                    url: `${USERS_URL}/getMyDetail`,
                    credentials: "include",
                    withCredentials: true,
                }
            },
            keepUnusedDataFor: 5,
        })
    })
})

export const {useGetMyDetailQuery} = userApiSlice;
import React from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

const ParticlesComponent = () => {
  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
        <Particles
        id="tsparticles"
        className="absolute inset-0"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
            fullScreen: {
            enable: false,
            },
            fpsLimit: 60,
            interactivity: {
            events: {
                onClick: {
                enable: true,
                mode: 'push',
                },
                onHover: {
                enable: true,
                mode: 'repulse',
                },
                resize: true,
            },
            modes: {
                push: {
                quantity: 0,
                },
                repulse: {
                distance: 100,
                duration: 0.4,
                },
            },
            },
            particles: {
            color: {
                value: '#ffffff',
            },
            links: {
                color: '#ffffff',
                distance: 100,
                enable: true,
                opacity: 0.5,
                width: 3, // Adjust the width to make the lines larger
            },
            collisions: {
                enable: false,
            },
            move: {
                direction: 'none',
                enable: true,
                outModes: {
                default: 'bounce',
                },
                random: false,
                speed: 1,
                straight: false,
            },
            number: {
                density: {
                enable: true,
                area: 800,
                },
                value: 100,
            },
            opacity: {
                value: 0.5,
            },
            shape: {
                type: 'circle',
            },
            size: {
                value: { min: 1, max: 8 },
            },
            },
            detectRetina: true,
        }}
        />
  );
};

export default ParticlesComponent;

import { apiSlice } from "../apislice";
import { SUBSCRIPTION_URL } from "../../constants"; 

export const SubscriptionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    subscribe: builder.mutation({
      query: (email) => ({
        url: `${SUBSCRIPTION_URL}/subscribe`, 
        method: "POST",
        body: { email },
      }),
    })
  })
});

export const { useSubscribeMutation } = SubscriptionApiSlice;

import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import { addToCart } from "../slices/cartslice";
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import { useGetProductWithIdQuery } from '../slices/getQueries/productsQueriesGet';
import { toast } from 'react-toastify';

const ProductPage = ({ match }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.userInfo);
  const cart = useSelector((state) => state.cart.cart);
  const [selectedColor, setSelectedColor] = useState('');
  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState('');
  const [productImages, setProductImages] = useState([]); 
  const [isOutOfStock, setIsOutOfStock] = useState(false);


  const {id} = useParams();
  const {data: product,isLoading:loaderForGetProduct,error:getProductError} = useGetProductWithIdQuery({id});
  useEffect(() => {
        if (product && product?.colors && product?.colors?.length > 0) {
          let selectedColor = null;
          let selectedSize = null;
          let images = null;
          let hasStock = false;
        
          // Find the first color with at least one available size
          for (const color of product.colors) {
            const availableSize = color.sizes.find(size => size.quantity > 0);
        
            if (availableSize) {
              selectedColor = color.color;
              selectedSize = availableSize.size;
              images = availableSize.images;
              hasStock = true;
              break; // Stop searching once we find the first available color and size
            }
          }
        
          // If no available color or size is found, use the first color and size as fallback
          if (!selectedColor && product?.colors?.length > 0) {
            const firstColor = product.colors[0];
            const firstSize = firstColor.sizes.length > 0 ? firstColor.sizes[0].size : null;
        
            selectedColor = firstColor.color;
            selectedSize = firstSize;
          }
        
          // Set the selected color and size in your state
          setIsOutOfStock(!hasStock);
          setSelectedColor(selectedColor);
          setSelectedSize(selectedSize);
          setSelectedImage(images[0].large);
          setProductImages(images);
        }
  }, [id,product]);


  useEffect(() => {
    // Update available sizes when the selected color changes
    if (product) {
      const selectedProductColor = product.colors.find(c => c.color === selectedColor);
      
      if (selectedProductColor) {
        // Set available sizes for the selected color
        setAvailableSizes(selectedProductColor.sizes);

        // If there's a selected size, update images accordingly
        const selectedProductSize = selectedProductColor.sizes.find(s => s.size === selectedSize);
        if (selectedProductSize) {
          setProductImages(selectedProductSize.images);
        } else {
          // Reset images if no size is selected yet
          setProductImages([]);
        }
      } 
    }
  }, [selectedColor, product,selectedSize]);

  const handleAddToCart = (itemCode, qty, price, name, size, variant, img) => {
      console.log(itemCode, qty, price, name, size, variant, img);
     dispatch(addToCart({itemCode, qty, price, name, size, variant, img}))
     
  };


  const handleColorChange = (selectedColor) => {
    setSelectedColor(selectedColor);
    const selectedProductColor  =  product.colors.find(c => c.color === selectedColor);
    if (selectedProductColor) {
      // Find the first size with a quantity greater than zero
      const firstAvailableSize = selectedProductColor.sizes.find(size => size.quantity > 0);
  
      // If such a size exists, set it as the selected size
      if (firstAvailableSize) {
        setSelectedSize(firstAvailableSize.size);
      } else {
        // Optionally reset the selected size if no size is available
        setSelectedSize('');
        setAvailableSizes([]);
      }
    }
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };

  const handleThumbnailClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleQuantityChange = (e) => {
    const value = Math.max(1, parseInt(e.target.value, 10)); // Minimum quantity 1
    setQuantity(value);
  };


  if (!product) {
    return <div><Loader /></div>;
  }

  return (
    <>
      {loaderForGetProduct && <Loader />
      }
    <section className="py-12 sm:px-10 px-2  sm:py-16"> 
    <div className="container mx-auto px-4">
    {getProductError?(
      <div>
        <p>Error loading product data. Please try refreshing the page or contact support if the issue persists.</p>
        <button onClick={() => window.location.reload()} className="text-blue-600 underline">Retry</button>
      </div>
    ):(
    <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
      <div className="lg:col-span-3 lg:row-end-1">
        <div className="lg:flex lg:items-start">
          <div className="lg:order-2 lg:ml-5">
            <div className="max-w-xl overflow-hidden rounded-lg">
            {productImages?.length > 0 ? (
          <img className="h-full w-full ..." src={selectedImage} alt={product.name} />
          ) : (
          <div className="text-center">No images available</div>
          )}
            </div>
          </div>

          <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
            <div className="flex flex-row items-start lg:flex-col">
            {productImages.map((imageObject, index) => (
            <button 
              key={index}
              className={`flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 text-center ${selectedImage === imageObject.large ?'border-gray-600 ring-2 ring-gray-600' : 'border-gray-200' }`}
              
              onClick={() => handleThumbnailClick(imageObject.large)}
            >
              <img className="h-full w-full object-cover" src={imageObject.thumbnail} alt="Thumbnail" />
            </button>
          ))}
            </div>
          </div>
        </div>
      </div>

      <div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
        <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl">{product.name}</h1>

        <div className="mt-5 flex items-center">
          <div className="flex items-center">
            <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
            </svg>
            <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
            </svg>
            <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
            </svg>
            <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
            </svg>
            <svg className="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" className=""></path>
            </svg>
          </div>
          <p className="ml-2 text-sm font-medium text-gray-500">1,209 Reviews</p>
        </div>
        <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
          <div className="flex">
            <span className="mr-3">Color</span>
            {product.colors.map((color) => (
            <button
              key={color._id}
              onClick={() => handleColorChange(color.color)}
              className={`border-2 rounded-full w-6 h-6 mx-1 focus:outline-none focus:ring-2 focus:ring-gray-800 ${color.color === selectedColor ? 'ring-4 ring-gray-800' : ''}`}
              style={{ backgroundColor: color.color }}
              disabled={isOutOfStock}
            />
          ))}
          </div>
          <div className="flex ml-6 items-center">
            <span className="mr-3">Size</span>
            <div className="relative">
              <select className="rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-red-500 text-base pl-3 pr-10" onChange={(e) => handleSizeChange(e.target.value)} value={selectedSize} disabled={isOutOfStock}>
              {availableSizes.map(value => (
                <option key={value.size} value={value.size} disabled={value.quantity === 0}>{value.size}</option>
              ))}
              </select> 
              <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4" viewBox="0 0 24 24">
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div className="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
          <div className="flex items-end">
            <h1 className="text-3xl font-bold">₹{product.price}</h1>
          </div>

          <button onClick={() =>{
            let productVariant = product.colors.find(color => color.color === selectedColor)?.sizes.find(size => size.size === selectedSize);
            if (productVariant) {
              handleAddToCart(productVariant._id, 1, product.price, product.name, selectedSize, selectedColor, product.productlink);
            } else {
              toast.error("Please select a valid color and size.");
            }
          }} className="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-yellow-500 bg-none px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-yellow-400" disabled={isOutOfStock}  >
            <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
            Add to cart
          </button>
        </div>

        <ul className="mt-8 space-y-2">
          <li className="flex items-center text-left text-sm font-medium text-gray-600">
            <svg className="mr-2 block h-5 w-5 align-middle text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" className=""></path>
            </svg>
            Free shipping all over india
          </li>
        </ul>
      </div>

      <div className="lg:col-span-3">
        <div className="border-b border-gray-300">
          <nav className="flex gap-4">
            <a href="#" title="" className="border-b-2 border-gray-900 py-4 text-sm font-medium text-gray-900 hover:border-gray-400 hover:text-gray-800"> Description </a>

            <a href="#" title="" className="inline-flex items-center border-b-2 border-transparent py-4 text-sm font-medium text-gray-600">
              Reviews
              <span className="ml-2 block rounded-full bg-gray-500 px-2 py-px text-xs font-bold text-gray-100"> 1,209 </span>
            </a>
          </nav>
        </div>

      </div>
    </div>)}
  </div>
</section>
</>
);
};

export default ProductPage;
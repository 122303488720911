import { TRANSACTION_URL } from "../../constants";
import { apiSlice } from "../apislice";

export const transactionPostApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) =>({
        preTransaction: builder.mutation({
            query: (data) =>({
                url: `${TRANSACTION_URL}/pretransaction`,
                method: "POST",
                body: data,
                credentials: "include",
                withCredentials: true,
            }),
        }),
        postTransaction: builder.mutation({
            query: (data) =>({
                url: `${TRANSACTION_URL}/postTransaction`,
                method: "POST",
                body: data,
                credentials: "include",
                withCredentials: true,
            }),
        }),
    })
})

export const {usePreTransactionMutation,usePostTransactionMutation} = transactionPostApiSlice;
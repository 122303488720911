import { Outlet } from "react-router-dom";
import Header from "./components/Navbar";
import Footer from "./components/Footer";
import "../src/index.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ComingSoon from "./components/coming-soon";


function App() {
  return (
    <div>
      <ComingSoon />
      {/* <ToastContainer />
    <div className="containerpro">
    <Header />
    <div className="midcontainer">
      <Outlet/>
    </div>
    <Footer /> */}
    </div>

  );
}

export default App;

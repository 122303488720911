import React from 'react'
import ReviewCard from './ReviewCard';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Slider = ({addClasses}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      swipeable={true}
      draggable={true}
      showDots={true}
      infinite={true}
      partialVisible={false}
      dotListClass="custom-dot-list-style"
      className={`${addClasses}`}
    >
      <div ><ReviewCard/></div>
      <div ><ReviewCard/></div>
      <div ><ReviewCard/></div>
      <div ><ReviewCard/></div>
      <div ><ReviewCard/></div>
    </Carousel>
  )
}

export default Slider


import React from "react";
import { useGetMyOrdersQuery } from "../slices/getQueries/orderQueriesGet";
import Loader from "../components/Loader";

const Orders = () => {
  const { data:orderdata, isLoading, error } = useGetMyOrdersQuery();

  // Check if orders is an array before using map
  const ordersArray = Array.isArray(orderdata?.orders) ? orderdata.orders : [];

  return (
    <div className="h-[100vh] px-8 md:px-20">
      <h2 className="text-3xl mt-5">Order History</h2>
      <p className="my-3">Manage your recent orders and invoices</p>
      {isLoading ? (
        <Loader/>
      ): error?(
        <div>Error loading. Please try again later.</div>
      ): (
        <div className="relative overflow-x-auto overflow-y-auto max-h-[80vh] shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Order Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              {ordersArray.map((item) => (
                <tr className="bg-white border-b" key={item._id}>
                  <td className="px-6 py-4">{item.orderId}</td>
                  <td className="px-6 py-4">{item.status}</td>
                  <td className="px-6 py-4">₹{item.amount}</td>
                  <td className="px-6 py-4">
                    <a
                      href={`/order?id=${item._id}&clearCart=1`}
                      className="font-medium text-blue-600 hover:underline"
                    >
                      Click here
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Orders;

import React from "react";

const Contact = () => {
  return <>
  <body class="text-gray-100 px-8 py-2">
      <div
        class="max-w-screen-xl m-12 px-8 grid gap-12 md:gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg">
        <div class="flex flex-col justify-between gap-y-4 md:gap-y-0">
          <div>
            <h2 class="text-4xl lg:text-5xl font-bold leading-tight">Lets talk about everything!</h2>
            <div class="text-gray-700 mt-8">
              Reach out us on <span class="underline">email</span> or <span class="underline">Whatsapp</span>.
            </div>
          </div>

          <div class="flex flex-col space-y-4 sm:space-y-6">
          <div class="inline-flex space-x-2 items-center">
            <i class="fa-solid fa-phone text-yellow-300 text-xl"></i>
            <span>+91 6378 332 491</span>
          </div>
          <div class="inline-flex space-x-2 items-center">
          <i class="fa-solid fa-envelope text-yellow-300 text-xl"></i>
            <span>theshapes3d@gmail.com</span>
          </div>
          <div class="inline-flex space-x-2 items-center">
          <i class="fa-solid fa-location-dot text-yellow-300 text-xl"></i>
            <span>Jagatpura,Jaipur-302017</span>
          </div>
        </div>

          <div class="mt-8 text-center">
          <ul id="buttons">
            <li class="fb">
              <a href="https://instagram.com/theshapes3d?igshid=YzAwZjE1ZTI0Zg==" title="Facebook">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li class="tw">
              <a href="https://instagram.com/theshapes3d?igshid=YzAwZjE1ZTI0Zg==" title="Twitter">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="in">
              <a href="https://instagram.com/theshapes3d?igshid=YzAwZjE1ZTI0Zg==" title="Instagram">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            {/* <li class="gh">
              <a href={"/"} title="Github">
                <i class="fab fa-github"></i>
              </a>
            </li> */}
          </ul> 
          </div>
        </div>
        <div class="">
          <div>
            <span class="uppercase text-sm text-gray-600 font-bold">Full Name</span>
            <input class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text" placeholder="" />
          </div>
          <div class="mt-8">
            <span class="uppercase text-sm text-gray-600 font-bold">Email</span>
            <input class="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              type="text" />
          </div>
          <div class="mt-8">
            <span class="uppercase text-sm text-gray-600 font-bold">Message</span>
            <textarea
              class="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
          </div>
          <div class="mt-8">
            <button
              class="uppercase text-sm font-bold tracking-wide bg-yellow-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
              Send Message
            </button>
          </div>
        </div>
      </div>
    </body>
  </>
};

export default Contact;

// ProductsPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetProductsQuery } from '../slices/getQueries/productsQueriesGet';
import Loader from '../components/Loader';

const ProductsPage = () => {
  const {data: products, isLoading,error} = useGetProductsQuery();
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {isLoading ? (
          <Loader /> // You can replace Loader with your loading component or message
        ) : error ? (
          <div>Error loading products. Please try again later.</div> // You can customize the error message
        ) : (
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {products?.map((product) => (
              <div key={product._id} className="bg-white shadow-md hover:scale-105 hover:shadow-xl duration-500">
                <Link to={`/product/${product._id}`}>
                  <img
                    src={product.colors[0].sizes[0].images[0].medium }
                    alt={product.name}
                    className="h-80 w-72 object-cover"
                  />
                </Link>
                <div className="px-4 py-3 w-72">
                  <span className="text-gray-400 mr-3 uppercase text-xs">Brand</span>
                  <p className="text-lg font-bold text-black truncate block capitalize">{product.name}</p>
                  <div className="flex items-center">
                    <p className="text-lg font-semibold text-black cursor-auto my-3">₹{product.price}</p>
                    <del>
                      <p className="text-sm text-gray-600 cursor-auto ml-2">₹199</p>
                    </del>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsPage;

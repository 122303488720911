import { apiSlice } from "../apislice";

export const utilApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) =>({
        getPincodeData: builder.query({
            query: () =>{
                return {
                    url: `/api/pincode`,
                }
            },
            keepUnusedDataFor: 5,
        })
    })
})

export const {useGetPincodeDataQuery} = utilApiSlice;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using react-router-dom for routing
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSendForgotPasswordMailMutation } from '../slices/postQueries/authQueriesPost';
import Loader from '../components/Loader';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [sendForgotPasswordMail,{isLoading,error}] = useSendForgotPasswordMailMutation();
  useEffect(() => {
    // Check if the user is already logged in using your own logic
    // You can use localStorage.getItem('myuser') or any other method
  }, []);

  const handleChange = (e) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await sendForgotPasswordMail({ email }).unwrap();
      if(res.success){
        toast.success("Mail Sent Successfully for resetting password!");
      }else{
        toast.error("Error Sending Mail!");
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
    {
      isLoading?(
        <Loader/>
      ):error?(
        <div>Error loading. Please try again later.</div>
      ):(
        <div>
      <section className="bg-gray-50">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 "
          >
            <img
              className="w-36 md:w-56 h-8 md:h-14"
              src="/images/logo/shapes3dlogo.png"
              alt="logo"
            />
          </a>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0  ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Forgot Password
              </h1>
              <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Your email
                  </label>
                  <input
                    onChange={handleChange}
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-yellow-600 focus:border-yellow-600 block w-full p-2.5  "
                    placeholder="name@company.com"
                    value={email}
                  />
                </div>
                <button
                  type="submit"
                  onClick={handlesubmit}
                  className="w-full text-white bg-yellow-600 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Continue
                </button>
                <p className="text-sm font-light text-gray-500">
                  Dont have an account yet?{" "}
                  <Link
                    href="/signup"
                    className="font-medium text-yellow-600 hover:underline"
                  >
                    Sign up
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
      )
    }
    </>
    
  );
};

export default Forgot;


import { USERS_URL } from "../../constants";
import { apiSlice } from "../apislice";

export const authPOSTApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/login`,
        method: "POST",
        body: data,
        withCredentials: true,
        credentials: 'include',
      }), 
    }),
    logoutApiCall: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/logout`,
        method: "POST",
        body: data,
        withCredentials: true,
        credentials: 'include',
      }), 
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/signup`,
        method: "POST",
        body: data,
      }),
    }),
    sendWelcomeMail: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/sendWelcomeMail`,
        method: "POST",
        body: data,
      }),
    }),
    confirmWelcomeMail: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/confirmWelcomeMail`,
        method: "POST",
        body: data,
      }),
    }),
    sendForgotPasswordMail: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/sendForgotPasswordMail`,
        method: "POST",
        body: data,
      }),
    }),
    confirmpasswordmail: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/confirmPasswordMail`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useLogoutApiCallMutation,useSendWelcomeMailMutation,useConfirmWelcomeMailMutation,useSendForgotPasswordMailMutation,useConfirmpasswordmailMutation } =
authPOSTApiSlice;

import { USERS_URL } from "../../constants";
import { apiSlice } from "../apislice";

export const userPostApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) =>({
        updateMyPassword: builder.mutation({
            query: (data) => ({
              url: `${USERS_URL}/updateMyPassword`,
              method: "POST",
              body: data,
              credentials: "include",
              withCredentials: true,
            }), 
          }),
          updateMyDetail: builder.mutation({
            query: (data) => ({
              url: `${USERS_URL}/updateMyDetail`,
              method: "POST",
              body: data,
              credentials: "include",
              withCredentials: true,
            }), 
          })
    })
})

export const {useUpdateMyPasswordMutation,useUpdateMyDetailMutation} = userPostApiSlice;
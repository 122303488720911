import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider, 
} from "react-router-dom"; 
import { Provider } from "react-redux";
import { store } from "./store";
import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './screens/home';
import Login from './screens/login';
import Signup from './screens/signup';
import Forgot from './screens/forgot';
import Uniqueproduct from './screens/uniqueproducts';
import ProductPage from './screens/Product';
import Checkout from './screens/checkout';
import PrivateRoute from './components/PrivateRoute';
import Orders from './screens/orders';
import MyAccount from './screens/myaccount';
import ResetPassword from './screens/resetpassword';
import Contact from './screens/contact';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
    <Route index={true} path='/' element={<Home />} />
    <Route path='/login' element={<Login/>}/>
    <Route path='/signup' element={<Signup/>}/>
    <Route path='/forgot' element={<Forgot/>}/>
    <Route path='/uniqueproducts' element={<Uniqueproduct/>}/>
    <Route path='/product/:id' element={<ProductPage/>}/>
    <Route path='/resetpassword' element={<ResetPassword/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path="" element={<PrivateRoute />}>
    <Route path="/checkout" element={<Checkout />} />
    <Route path="/orders" element={<Orders/>} />
    <Route path="/myAccount" element={<MyAccount/>}/>
    </Route>
    
    {/* <Route path='login' element={<LoginScreen />} />
    <Route path='forgot' element={<ResetLink/>} />
    <Route path='resetPassword' element={<ResetPassword/>} />
    <Route path='' element={<PrivateRoute/>}>
    <Route index={true} path="/" element={<HomeScreen />} />
    <Route path='report/:modid' element={<ReportScreen />}/>
    </Route> */}
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

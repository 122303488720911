import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import Cookies from 'js-cookie';

import { BASE_URL } from "../constants";
// import { logout } from "./authslice";

// const baseQuery = fetchBaseQuery({ 
//   baseUrl: BASE_URL,
//   prepareHeaders(headers) {
//     const token = Cookies.get('jwt');
//     if (token) {
//       headers.set('Token', token);
//     }
//     return headers;
//   },
// });

// async function baseQueryWithAuth(args, api, extra) {
//   const result = await baseQuery(args, api, extra);
//   // Dispatch the logout action on 401.
//   if (result.error && result.error.status === 401) {
//     api.dispatch(logout());
//   }
//   return result;
// }

const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL });

export const apiSlice = createApi({
    baseQuery,
    tagTypes: [],
    endpoints: (builder) => ({}),
  });
  